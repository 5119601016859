import { abortNavigation, defineNuxtRouteMiddleware, navigateTo } from '#app';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';

export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn } = storeToRefs(useAuthStore()); // make authenticated state reactive

  // if logged in and url is /login redirect to homepage
  if (isLoggedIn.value && to?.name === 'login') {
    return navigateTo('/');
  }

  // if is not logged in and no token - redirect to homapage and open log in dialog
  if (!isLoggedIn.value && to?.name !== 'login') {
    abortNavigation();

    return navigateTo('/?action=login');
  }
});
